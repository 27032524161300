<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import {removeToken, setToken} from "./utils/auth";

export default {

  mounted() {
    window.onbeforeunload = function (e) {
      // removeToken()
    };
  }

}

</script>

<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    background-color: #282c34;
  }

  #app ~ .textCenter {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .el-tooltip__popper.is-dark {
    background-color: rgba(172, 171, 171, 0.4) !important;
  }
  .el-tooltip__popper.is-dark.dark-tip{
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  /*-------------------------------------------------------------------*/
  //以下是月份选择器
  .el-picker-panel{
    background-color: #274084 !important;
    border: 1px solid #274084 !important;
  }
  .el-picker-panel__icon-btn{
    color: #c0c4cc !important;
  }

  .el-date-picker{
    //top: 650px !important;
    //left: 1600px !important;
    transform: scale(.8);
    //padding-left: -70px !important;
    //width: 265px !important;
    //display: flex !important;
    //justify-content: center !important;

  }
  .el-date-picker__header--bordered{
    border-bottom: solid 1px #274084 !important;
  }
  .el-date-picker__header-label:hover{
    color: #47acff !important;
  }
  .el-date-picker__header-label{
    color: #c0c4cc !important;
  }

  .el-month-table td.current:not(.disabled) .cell{
    color: #47acff !important;
  }
  .el-month-table td .cell:hover{
    color: #47acff !important;
  }
  .el-month-table td.today .cell{
    color: #47acff !important;
  }
  .el-month-table td .cell{
    color: #c0c4cc !important;
  }
  .el-year-table td .cell:hover, .el-year-table td.current:not(.disabled) .cell{
    color: #47acff !important;
  }
  .el-year-table td.today .cell{
    color: #47acff !important;
  }
  .el-year-table td .cell{
    color: #c0c4cc !important;
  }
  .el-zoom-in-top-enter-active {

    //opacity:1;
    //transform:scaleY(1);
    transition:transform 0s !important;
    //transform-origin:center top;
    //width: 250px !important;
    //height: 200px !important
  }
  .el-zoom-in-top-enter-to{
    //width: 100px!important;
    //height: 100px !important;
  }
  /*-------------------------------------------------------------------*/
/*  .el-zoom-in-top-enter-active,
  .el-zoom-in-top-leave-active{
    opacity:1;
    transform:scaleY(1);
    transition:transform .3s cubic-bezier(.23,1,.32,1),opacity .3s cubic-bezier(.23,1,.32,1);
    transform-origin:center top
  }*/
</style>
